import NotificationEmptyState from "components/NotificationEmptyState";
import LoaderView from "../../components/LoaderView";
import Layout from "../../components/v2/Layout";
import React from "react";

export default ({}) => {
  const [ loading, setLoading ] = React.useState(true);

  return (
    <Layout bg='bg3' title='comingsoon'>
      <LoaderView
        loading={loading}
        empty={loading}
        emptyText='แล้วพบกันเร็วๆนี้'
        colorText='white'
      >
        <p>แล้วพบกันเร็วๆนี้</p>
      </LoaderView>
    </Layout>
  )
}